
import ShippingAddress from "containers/PageCheckout/ShippingAddress";
import CommonLayout from "./CommonLayout";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { useEffect, useState, ChangeEventHandler } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { addUserAddress, getUserAddresses } from "network/services/account";
import toast from "react-hot-toast";
import { verifyAddress } from "utils/verifyAddress";
import countries from 'utils/getCountries';
import { getStates } from "network/services/getStates";
import { getLocalStorage } from "network/helper";

const AccountBilling = () => {

  const [userDetails, setUserDetails] = useState<{email: string}>({email: ""})
  const [tabActive, setTabActive] = useState<
    true | false
  >(false);

  const [addNewAddressEnabled, setAddNewAddressEnabled] = useState<
    true | false
  >(false);

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };

  interface AddressDetails {
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    address: string;
    district: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    altContact: string;
  }

  const [addressDetails, setAddressDetails] = useState<AddressDetails>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    address: "",
    district: "",
    city: "",
    state: "",
    country: "India",
    pincode: "",
    altContact: "",
  });

  interface AddressDetailsFromAPI {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    address: string;
    district: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    altContact: string;
  }

  const [savedAddresses, setSavedAddresses] = useState<AddressDetailsFromAPI[]>(
    []
  );

  const [states, setStates] = useState<Array<string>>([])

  async function getSavedAddresses() {
    const savedAddresses = await getUserAddresses();
    setSavedAddresses(savedAddresses.data);
  }

  const fetchStates = async () => {
    const res = await getStates()
    if(res.data && Array.isArray(res.data))
      setStates(res.data)
    else
      toast.error("Error while loading states, please reload the page")
  }

  useEffect(() => {
    fetchStates()
    const user = getLocalStorage('user_details')
    setUserDetails(user)
  }, [])

  useEffect(() => {
    getSavedAddresses();
  }, [addNewAddressEnabled]);

  const checkLeadingSpace = (input: string, name: string) => {
    if(name == "firstName" && input == " " && addressDetails.firstName == "") return true
    if(name == "lastName" && input == " " && addressDetails.lastName == "") return true
    if(name == "address" && input == " " && addressDetails.address == "") return true
    if(name == "email" && input == " " && addressDetails.email == "") return true
    if(name == "district" && input == " " && addressDetails.district == "") return true
    if(name == "city" && input == " " && addressDetails.city == "") return true
    if(name == "state" && input == " " && addressDetails.state == "") return true
    
    return false
  }


  async function addNewAddress() {
    const error = verifyAddress({...addressDetails, email: userDetails.email})
    if (error != null) {
      toast.error(error);
      return
    }
    const res = await addUserAddress({...addressDetails, email: userDetails.email})
    if (res && res.data) {
      toast.success("Address Successfully Saved");
      setAddressDetails({
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        address: "",
        district: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        altContact: "",
      })
    } else {
      if(res?.data?.message)
        toast.error(res?.data?.message);
      if(res.errRes?.data?.message)
        toast.error(res.errRes.data.message)
    }
    setAddNewAddressEnabled(false)
  }

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e: any) => {
    if(checkLeadingSpace(e.target.value, e.target.name)) return
    if(e.target.type == "number") {
      if((e.target.name == "phoneNo" || e.target.name == "altContact") && e.target.value.length > 10) {
        toast.error("Maximum length is 10 digits")
        return
      }
      if(e.target.name == "pincode" && e.target.value.length > 6) {
        toast.error("Invalid Pin-Code")
        return
      }
    }
    setAddressDetails(state => ({
      ...state,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <div>
      <CommonLayout>
        <div>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl sm:text-3xl mb-4 font-semibold">
              Your Addresses
            </h2>
            <h1 className="underline cursor-pointer" onClick={() => setAddNewAddressEnabled(true)}>+ Add New Address</h1>
          </div>
          <hr />
        </div>
        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${addNewAddressEnabled ? "block" : "hidden"
            }`}
        >
          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">First name *</Label>
              <Input className="mt-1.5" placeholder="Enter your first name" type="text" value={addressDetails.firstName} onChange={handleChange} name="firstName" />
            </div>
            <div>
              <Label className="text-sm">Last name *</Label>
              <Input className="mt-1.5" placeholder="Enter your last name" type="text" value={addressDetails.lastName} onChange={handleChange} name="lastName" />
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Phone Number *</Label>
              <Input className="mt-1.5" placeholder="Enter your phone number" type="number" maxLength={10} value={addressDetails.phoneNo} onChange={handleChange} name="phoneNo" />
            </div>
            <div>
              <Label className="text-sm">Alt Number</Label>
              <Input className="mt-1.5" placeholder="Enter alternate contact" type="number" maxLength={10} value={addressDetails.altContact} onChange={handleChange} name="altContact"/>
            </div>
          </div>

          {/* <div>
              <Label className="text-sm">Email *</Label>
              <Input className="mt-1.5" placeholder="Enter your email" type="email" value={addressDetails.email} onChange={handleChange} name="email"/>
          </div> */}

          {/* ============ */}
          <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
            <div className="flex-1">
              <Label className="text-sm">Address *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter delivery address"
                type={"text"}
                value={addressDetails.address} onChange={handleChange} name="address"
              />
            </div>
            <div className="sm:w-1/3">
              <Label className="text-sm">Country *</Label>
              <Select name="country" value={addressDetails.country} onChange={handleChange} className="mt-1.5">
                <option value="">Select a country</option>
                {
                  countries.map((country: string, index: number) => {
                    return (
                      <option value={country} key={index}>{country}</option>
                    )
                  })
                }
              </Select>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">State *</Label>
              <Select name="state" value={addressDetails.state} onChange={handleChange} className="mt-1.5">
                <option value="">Select a state</option>
                {
                  states.map((state: string, index: number) => {
                    return (
                      <option value={state} key={index}>{state}</option>
                    )
                  })
                }
              </Select>
            </div>
            <div>
              <Label className="text-sm">District</Label>
              <Input className="mt-1.5" placeholder="Enter your district" type="text" value={addressDetails.district} onChange={handleChange} name="district"/>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">City *</Label>
              <Input className="mt-1.5" placeholder="Enter your city" type="text" value={addressDetails.city} onChange={handleChange} name="city"/>
            </div>
            <div>
              <Label className="text-sm">Postal code *</Label>
              <Input className="mt-1.5" placeholder="Enter your postal code" type="number" maxLength={6} value={addressDetails.pincode} onChange={handleChange} name="pincode" />
            </div>
          </div>

          {/* ============ */}
          <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
              className="sm:!px-7 shadow-none"
              onClick={addNewAddress}
            >
              Save
            </ButtonPrimary>
            <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={() => {
                setAddressDetails({
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNo: "",
                  address: "",
                  district: "",
                  city: "",
                  state: "",
                  country: "",
                  pincode: "",
                  altContact: "",
                })
                setAddNewAddressEnabled(false)
              }}
            >
              Cancel
            </ButtonSecondary>
          </div>
        </div>
        {
          savedAddresses.map((address, index) => {
          return (
            <div id="ShippingAddress" className="scroll-mt-24 mt-5" key={address._id}>
              <ShippingAddress
                data={address}
                states={states}
              />
            </div>
          )})
        }
      </CommonLayout>
    </div>
  );
};

export default AccountBilling;
