import Label from "components/Label/Label";
import { deleteUserAddress, getUserAddresses, updateUserAddress } from "network/services/account";
import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { verifyAddress } from "utils/verifyAddress";
import countries from "utils/getCountries";

interface AddressDetailsFromAPI {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  address: string;
  district: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  altContact: string;
}

interface Props {
  data: AddressDetailsFromAPI;
  states: Array<string>
}

const ShippingAddress: FC<Props> = ({
  data,
  states
}) => {
  interface AddressDetails {
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    address: string;
    district: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    altContact: string;
    _id: string
  }

  const [addressDetails, setAddressDetails] = useState<AddressDetails>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    address: "",
    district: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    altContact: "",
    _id: ""
  });


  const [isActive, setIsActive] = useState<Boolean>(false);

  useEffect(() => {
    setAddressDetails(data);
  }, [data]);

  async function updateAddress(){
    const error = verifyAddress({...addressDetails})
    if(error != null) {
      toast.error(error)
      return 
    }
    const res = await updateUserAddress(addressDetails._id, addressDetails)
    if (res && res.data) {
      toast.success("Address Successfully Updated");
      setIsActive(false)
      window.location.reload()
    } else {
      toast.error(res?.data?.message);
    }
  }

  async function removeAddress(){
    const res = await deleteUserAddress(addressDetails._id)
    if (res && res.data) {
      toast.success("Address Successfully Removed");
      setIsActive(false)
      window.location.reload()
    } else {
      toast.error(res?.data?.message);
    }
  }


  const renderShippingAddress = () => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="flex justify-between w-full" key={data._id}>
            <div className="sm:ml-8">
              <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                <span className="uppercase">{`${data.firstName} ${data.lastName}`}</span>
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2.5"
                  stroke="currentColor"
                  className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
              </h3>
              <div className="font-semibold mt-1 text-sm">
                <span className="">
                  {`${data.address}, ${data.district}, ${data.city}, ${data.state}, ${data.country} - ${data.pincode}`}
                </span>
              </div>
            </div>
            <div className="flex justify-around gap-5">
              <ButtonSecondary
                sizeClass="py-2 px-4 "
                fontSize="text-sm font-medium"
                className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                onClick={() => {isActive ? setIsActive(false) : setIsActive(true)}}
              >
                Change
              </ButtonSecondary>
              <ButtonSecondary
                sizeClass="py-2 px-4 "
                fontSize="text-sm font-medium"
                className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                onClick={removeAddress}
              >
                Remove
              </ButtonSecondary>
            </div>
          </div>
        </div>

        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${isActive ? "block" : "hidden"
            }`}
        >
          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">First name *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your first name"
                type="text"
                value={addressDetails.firstName}
                onChange={(e) =>
                  setAddressDetails({
                    ...addressDetails,
                    firstName: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <Label className="text-sm">Last name *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your last name"
                type="text"
                value={addressDetails.lastName}
                onChange={(e) =>
                  setAddressDetails({
                    ...addressDetails,
                    lastName: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            
            <div>
              <Label className="text-sm">Phone Number *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your phone number"
                type="tel"
                value={addressDetails.phoneNo}
                onChange={(e) => {
                  if(e.target.value.length > 10) return toast.error("Maximum length is 10 digits")
                  setAddressDetails({
                    ...addressDetails,
                    phoneNo: e.target.value,
                  })
                }
                }
              />
            </div>
            <div>
              <Label className="text-sm">Alt Contact</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter alternate contact number"
                type="number"
                value={addressDetails.altContact}
                onChange={(e) => {
                  if(e.target.value.length > 10) return toast.error("Maximum length is 10 digits")
                  setAddressDetails({
                    ...addressDetails,
                    altContact: e.target.value,
                  })
                }
                }
              />
            </div>
          </div>
            {/* <div>
              <Label className="text-sm">Email *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your email"
                type="email"
                value={addressDetails.email}
                onChange={(e) =>
                  setAddressDetails({
                    ...addressDetails,
                    email: e.target.value,
                  })
                }
              />
            </div> */}

          {/* ============ */}
          <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
            <div className="flex-1">
              <Label className="text-sm">Address</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter delivery address"
                type={"text"}
                value={addressDetails.address}
                onChange={(e) =>
                  setAddressDetails({
                    ...addressDetails,
                    address: e.target.value,
                  })
                }
              />
            </div>
            <div className="sm:w-1/3">
              <Label className="text-sm">Country *</Label>
              <Select name="country" value={addressDetails.country} onChange={(e) => {
                setAddressDetails({
                  ...addressDetails,
                  country: e.target.value
                })
              }} className="mt-1.5">
                <option value="">Select a country</option>
                {
                  countries.map((country: string, index: number) => {
                    return (
                      <option value={country} key={index}>{country}</option>
                    )
                  })
                }
              </Select>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
          <div>
              <Label className="text-sm">State *</Label>
              <Select name="state" value={addressDetails.state} onChange={(e) => {
                setAddressDetails({
                  ...addressDetails,
                  state: e.target.value
                })
              }} className="mt-1.5">
                <option value="">Select a state</option>
                {
                  states.map((state: string, index: number) => {
                    return (
                      <option value={state} key={index}>{state}</option>
                    )
                  })
                }
              </Select>
            </div>
            <div>
              <Label className="text-sm">District</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter district"
                type="text"
                value={addressDetails.district}
                onChange={(e) =>
                  setAddressDetails({
                    ...addressDetails,
                    district: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">City *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your city"
                type="text"
                value={addressDetails.city}
                onChange={(e) =>
                  setAddressDetails({
                    ...addressDetails,
                    city: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <Label className="text-sm">Postal code *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your postal code"
                type="number"
                value={addressDetails.pincode}
                onChange={(e) =>
                  setAddressDetails({
                    ...addressDetails,
                    pincode: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* ============ */}
          <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary className="sm:!px-7 shadow-none" onClick={updateAddress}>
              Save
            </ButtonPrimary>
            <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={() => {isActive ? setIsActive(false) : setIsActive(true)}}
            >
              Cancel
            </ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddress;
