import { Helmet } from "react-helmet-async";
import SectionHero2 from "components/SectionHero/SectionHero2";
import Heading from "components/Heading/Heading";
import MyVoucherAddressField from "./MyVoucherAddressField";
import { useEffect, useState, FC, ChangeEventHandler } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductSelectionSection from "./ProductSelection";
import { getVoucherProducts } from "network/services/account";
import { getLocalStorage } from "network/helper";
import { placeVoucherOrder, placeVoucherOrderv2 } from "network/services/order";
import { toast } from "react-hot-toast";
import SuccessModal from "components/SuccessModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import AddressForm from "containers/CartPage/AddressForm";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { verifyAddress } from "utils/verifyAddress";

interface VoucherOrderData {
    department?: string,
    name: string,
    email: string,
    address: string,
    district?: string,
    altContact?: string,
    contactNo: string,
    state: string,
    city: string,
    country: string,
    pincode: string,
    purchaseOrder?: string,
    voucherType: string,
    data: Array<{ product: string, entity: string | null }>,
    userVoucherId: string,
}

const MyVoucherDetailsPage: FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [voucher, setVoucher] = useState<any>(null)
    const [userDetails, setUserDetails] = useState<{ username: string, email: string }>({ username: "", email: "" })
    const [data, setData] = useState<Array<{ category: string, selected: { product: string, entity: string | null }, products: Array<any> }>>([])
    const [isCombo, setIsCombo] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [address, setAddress] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        address: "",
        district: "",
        city: "",
        state: "",
        country: "",
        altContact: "",
        pincode: "",
        _id: ""
    })

    const handleAddressChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
        if (checkLeadingSpace(e.target.value, e.target.name)) return
        if ((e.target.name === "altContact" || e.target.name === "phoneNo") && e.target.value.length > 10) return toast.error("Maximum length is 10 digits")
        setAddress((state) => {
            return {
                ...state,
                [e.target.name]: e.target.value
            }
        })
    }


    const fetchVoucherProducts = async (voucherId: string) => {
        const res = await getVoucherProducts(voucherId)
        if (!res.data) {
            if (res.errRes.data && res.errRes.data.message)
                toast.error(res.errRes.data.message)
            navigate("/my-vouchers")
            return
        }
        const formattedData = res.data.map((item: any) => ({
            category: item.nameOfCategory,
            selected: {
                product: null,
                entity: null,
            },
            products: item.products
        }))
        setData(formattedData)
    }

    useEffect(() => {
        if (!location.state || location.state == null) {
            navigate('/my-vouchers')
            return
        }
        console.log(location.state)
        setVoucher(location.state)
        console.log(location.state, "location.state")
        if (location.state && !(location.state.isClaimed)) {
            fetchVoucherProducts(location.pathname.split('/').slice(-1)[0])
            if (location.search.split('=')[1] == "true")
                setIsCombo(true)
        }
        const userDetails = getLocalStorage('user_details')
        if (userDetails) {
            setUserDetails({ username: userDetails.name, email: userDetails.email })
            setAddress(state => ({ ...state, email: userDetails.email }))
        }
    }, [location])

    const handleSelectComboProduct = (categoryIndex: number, productId: string, entityId: string | null) => {
        setData((state) => {
            const tempState = [...state]
            tempState[categoryIndex].selected = {
                product: productId,
                entity: entityId
            }
            return tempState;
        })
    }

    const handleSelectProduct = (categoryIndex: number, productId: string, entityId: string | null) => {
        setData((state) => {
            const tempState = [...state].map((category: any, index: number) => {
                const tempCat = { ...category }

                if (categoryIndex == index) tempCat.selected = { product: productId, entity: entityId }
                else tempCat.selected = { product: null, entity: null }

                return tempCat
            })
            return tempState
        })
    }

    const getSelectedItems = () => {
        if (isCombo)
            return data.map((cat) => ({ ...(cat.selected) }))
        else {
            const cat = data.find((cat) => cat.selected.product != null)
            if (cat) return [cat.selected]
            else return []
        }
    }

    const checkProductsSelected = () => {
        const selectedProducts = getSelectedItems()
        if (selectedProducts.length == 0) {
            toast.error("Please Choose The Products Available")
            return false;
        }
        if (isCombo && selectedProducts.length < data.length) {
            toast.error("Please Choose A Product From Each Category")
            return false;
        }
        return true;
    }

    const handlePlaceOrder = async (contactDetails: { name: string, contactNo: string, altContact: string, address: string }) => {
        const selectedProducts = getSelectedItems()
        const orderDetails = {
            ...contactDetails,
            data: selectedProducts,
            purchaseOrder: (voucher.purchaseOrderRef) ? voucher.purchaseOrderRef : "qeqwer",
            voucherType: voucher.voucherTypeId._id,
            userVoucherId: voucher._id,
            email: userDetails.email
        }
        const loading = toast.loading("requesting...");
        try {
            const response: any = await placeVoucherOrder(orderDetails);
            if (response?.data) {
                toast.dismiss(loading)
                setShowSuccess(true)
            } else {
                toast.dismiss(loading);
                toast.error(response?.errRes.data?.message);
            }
        } catch (error: any) {
            toast.dismiss(loading);
            toast.error(error?.errRes.data?.message);
        }
    }

    const checkLeadingSpace = (input: string, name: string) => {
        if (name == "firstName" && input == " " && address.firstName == "") return true
        if (name == "lastName" && input == " " && address.lastName == "") return true
        if (name == "address" && input == " " && address.address == "") return true
        if (name == "email" && input == " " && address.email == "") return true
        if (name == "district" && input == " " && address.district == "") return true
        if (name == "city" && input == " " && address.city == "") return true
        if (name == "state" && input == " " && address.state == "") return true

        return false
    }

    const handleSubmit = async () => {
        if (!checkProductsSelected()) return
        const verifyMessage: string | null = verifyAddress(address)
        if (verifyMessage != null) {
            toast.error(verifyMessage)
            return
        }




        const selectedProducts = getSelectedItems()
        const voucherOrderDetails: VoucherOrderData = {
            name: address.firstName + " " + address.lastName,
            email: address.email,
            contactNo: address.phoneNo,
            address: address.address,
            city: address.city,
            state: address.state,
            country: address.country,
            pincode: address.pincode,
            data: selectedProducts,
            department: voucher.department,
            voucherType: voucher.voucherTypeId._id,
            userVoucherId: voucher._id,
        }
        if (voucher.voucherTypeId.purchaseOrder && voucher.voucherTypeId.purchaseOrder.trim()) voucherOrderDetails.purchaseOrder = voucher.voucherTypeId.purchaseOrder

        if (address.district) voucherOrderDetails.district = address.district
        if (address.altContact) voucherOrderDetails.altContact = address.altContact

        const loading = toast.loading("requesting...");
        try {
            const response: any = await placeVoucherOrderv2(voucherOrderDetails);
            if (response?.data) {
                toast.dismiss(loading)
                setShowSuccess(true)
            } else {
                toast.dismiss(loading);
                toast.error(response?.errRes.data?.message);
            }
        } catch (error: any) {
            toast.dismiss(loading);
            toast.error(error?.errRes.data?.message);
        }
    }

    return (voucher && !voucher.isClaimed) ? (

        <div className="nc-PageHome relative overflow-hidden">
            {
                voucher && (
                    <SuccessModal
                        showModal={showSuccess}
                        successMessage="Order Placed Successfully."
                        redirectMessage="Redirecting to orders page..."
                        path={"/my-order/voucher/" + voucher._id}
                    />
                )
            }
            <Helmet>
                <title>Vouchers Combo</title>
            </Helmet>
            <div className="bg-gray-300">
                {/* <ButtonPrimary>Select</ButtonPrimary> */}
                <SectionHero2 data={voucher && voucher.voucherTypeId ? [voucher.voucherTypeId.sliderImage] : null} />
            </div>
            <div className=" mt-20 px-10">
                <Heading className="font-bold text-gray-700 text-2xl capitalize">Welcome {userDetails.username}</Heading>
                {/* Converted the html content to raw */}
                <p className="text-gray-500 mt-3" dangerouslySetInnerHTML={{ __html: !!voucher ? voucher.voucherTypeId.congratulatoryText : "Congratulations" }} />

            </div>
            <div className="py-6 pt-24 container">
                {
                    isCombo ? (
                        <ProductSelectionSection
                            data={data}
                            handleSelectProduct={handleSelectComboProduct}
                        />
                    ) : (
                        <ProductSelectionSection
                            data={data}
                            handleSelectProduct={handleSelectProduct}
                        />
                    )
                }
                <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
                    <AddressForm value={address} onChange={handleAddressChange} />
                    <div className="flex flex-col justify-between sm:flex-row pt-6">
                        {/* <ButtonSecondary className="sm:!px-7 sm:my-2 my-2  border border-slate shadow-none">
                        Cancel
                        </ButtonSecondary> */}
                        <ButtonPrimary className="sm:!px-7 shadow-none my-2 " onClick={handleSubmit}>
                            Place Order
                        </ButtonPrimary>
                    </div>
                    {/* <MyVoucherAddressField handlePlaceOrder={handlePlaceOrder} checkProductsSelected={checkProductsSelected}/> */}

                    <hr className="border-slate-200 dark:border-slate-700" />
                </div>
            </div>
        </div>
    ) : (

        <div className="nc-PageHome relative overflow-hidden pb-[20vh]">
            {
                voucher && (
                    <SuccessModal
                        showModal={showSuccess}
                        successMessage="Order Placed Successfully."
                        redirectMessage="Redirecting to orders page..."
                        path={"/my-order/voucher/" + voucher._id}
                    />
                )
            }
            <Helmet>
                <title>Vouchers Combo</title>
            </Helmet>
            <div className="bg-gray-300">
                <SectionHero2 data={voucher && voucher.voucherTypeId ? [voucher.voucherTypeId.sliderImage] : null} />
            </div>
            <div className="mt-20 px-10">
                <Heading className="font-bold text-gray-700 text-2xl capitalize">Welcome {userDetails.username}</Heading>
                {/* <p className="text-gray-500 mt-3">{!!voucher ? voucher.voucherTypeId.congratulatoryText : "Congratulations"}</p> */}
                <p className="text-gray-500 mt-3" dangerouslySetInnerHTML={{ __html: !!voucher ? voucher.voucherTypeId.congratulatoryText : "Congratulations" }} />

            </div>
            <div className="mx-auto mt-24">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto" xmlnsXlink="http://www.w3.org/1999/xlink" width={100} height={100} version="1.1" id="Capa_1" viewBox="0 0 50 50" xmlSpace="preserve">
                        <circle style={{ fill: '#25AE88' }} cx="25" cy="25" r="25" />
                        <polyline style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} points="38,15 22,33 12,25" />
                    </svg>
                </div>
                <h3 className="text-center font-semibold text-primary-6000 text-2xl mt-5">Voucher Already Claimed!!</h3>
                <p className="text-center mt-5">Go to orders page to view the voucher order details</p>
                <div className="flex flex-row justify-center mt-2">
                    <ButtonSecondary onClick={() => navigate("/account-my-order")} className="border border-2 border-black">
                        Click Here
                    </ButtonSecondary>

                </div>
            </div>
        </div>
    )
};

export default MyVoucherDetailsPage;
