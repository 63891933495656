import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { forgotpassword } from "network/services/auth";
import toast from "react-hot-toast";
import { getLocalStorage } from "network/helper";

export interface PageLoginProps {
  className?: string;
}



const ForgotPassword: FC<PageLoginProps> = ({ className = "" }) => {
  const [resetEmail, setResetEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [ecommEnabled, setEcommEnabled] = useState(true)

  useEffect(() => {
    const company = getLocalStorage('company')
    setEcommEnabled(company.isEcommerceEnabled)
  }, [])

 const sendEmail = async() => {
  try {

    const response = await forgotpassword({email: resetEmail.toLowerCase(), company_id: getLocalStorage("company")._id});
    
    if (response?.data) {
      toast.success(response?.data?.message);
      setEmailSent(true);
    } else {
      toast.error(response?.errRes?.data?.message);
    }
  } catch (error) {
    console.log(error);
    
  }

 }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forgot Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">

          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
          {
              emailSent &&
              <div>
                <p className="text-center mb-5">An email with reset link has been sent to your registered mail id.  <Link className="text-green-600" to="/login">
              Click here to login
            </Link></p>
              </div>
              
              }
           <>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </label>
            <ButtonPrimary type="button" onClick={() => sendEmail()}>Send reset link</ButtonPrimary>
           </>
            
          </form>

          {/* ==== */}
          {
            ecommEnabled && (
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                New user? {` `}
                <Link className="text-green-600" to="/signup">
                  Create an account
                </Link>
              </span>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
