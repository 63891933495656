import React, { FC, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { addCatalogue, getCatalogue } from "../network/services/HR";
import toast from "react-hot-toast";
export interface ProblemPlan {
  name: string;
  id: string;
  label: string;
}

export interface ModalReportItemProps {
  show: boolean;
  problemPlans?: ProblemPlan[];
  onCloseModalReportItem: () => void;
  onclick?: () => void;
  product_id?: string;
}

const AddToCatalogueModal: FC<ModalReportItemProps> = ({
  show,
  onCloseModalReportItem,
  onclick,
  product_id,
}) => {
  const textareaRef = useRef(null);

  const [catalogue, setCatalogue] = useState<any>([]);
  const [selectedCatID, setSelectedCatID] = useState("");

  useEffect(() => {
    getCataloguesFromAPI()
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
        }
      }, 400);
    }
  }, [show]);

  const getCataloguesFromAPI = async () => {
    try {
      const resp = await getCatalogue();
      if (resp?.data && resp?.data.length > 0) {
        setCatalogue(resp?.data);
      } else {
        // toast.error(resp?.data.message);
      }
    } catch (error) { }
  };

  const handleClickSubmitForm = async () => {
    let obj = {
      catalog_id: selectedCatID,
      product_id: product_id,
    };


    try {
      const resp = await addCatalogue(obj);
      if (resp?.data) {
        toast.success(resp?.data?.message);
        getCataloguesFromAPI()
        onCloseModalReportItem();
      } else {
        toast.error(resp?.errRes?.data?.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getCataloguesFromAPI();
  }, []);

  const renderContent = () => {
    return (
      <>
        <div className="mt-4">
          <div>
            <Label className="text-sm">Select Catalog</Label>
            <Select
              onChange={(e: any) => setSelectedCatID(e.target.value)}
              className="mt-1.5"
              defaultValue="No Catalog Selected"
              value={selectedCatID}
            ><option value="" disabled>
                No Catalog Selected
              </option>
              {catalogue &&
                catalogue.map((item: any) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
            </Select>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="mt-4 space-x-3">
            <ButtonPrimary onClick={handleClickSubmitForm} type="submit">
              Submit
            </ButtonPrimary>
            <ButtonSecondary type="button" onClick={onCloseModalReportItem}>
              Cancel
            </ButtonSecondary>
          </div>
          <div className="my-4 space-x-3">
            <ButtonSecondary onClick={onclick} type="submit">
              Create Catalog
            </ButtonSecondary>
          </div>
        </div>
      </>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalReportItem}
      contentExtraClass="max-w-screen-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle="Add to Catalog"
    />
  );
};

export default AddToCatalogueModal;
