import React, { FC, useState, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import WalletIcon from "./WalletIcon";
import { Link } from "react-router-dom";
import { getLocalStorage, isAuth, logout } from "network/helper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAvailablePoints } from "network/services/account";
import { SET_POINTS_ACTION } from "../../redux/actions/points";
import { getUserCartDetails } from "network/services/account";
import { ADD_CART_ACTION } from "../../redux/actions/cart";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const pointsSelector = useSelector((state: any) => state?.Points)
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [ecommerceEnabled, setEcommerceEnabled] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const getPoints = async () => {
    const res = await getAvailablePoints()
    if(res.errRes) {
      dispatch(SET_POINTS_ACTION(0))
      return
    }
    dispatch(SET_POINTS_ACTION(res.data.totalPoints))
  }

  const getCartDetailsFromAPI = async () => {
    try {
      const response = await getUserCartDetails();
      if (response?.data) {
        dispatch(ADD_CART_ACTION(response?.data));
      }
    } catch (error) {}
  };

  const getCompany = () => {
    const company = getLocalStorage('company')
    if(company) {
      setEcommerceEnabled(company.isEcommerceEnabled)
    }
  }

  useEffect(() => {
    getPoints()
    getCartDetailsFromAPI()
    getCompany();
  }, [])

  function isLoginPath() {
    // Get the current path
    const currentPath = window.location.pathname;

    // Check if the current path includes '/login'
    return currentPath.includes("/login");
  }

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate(`/page-search?name=${inputRef.current?.value}`);
        }}
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Search your merchandise"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
          />
          {inputRef.current?.value && (
            <button
              type="button"
              onClick={() => {
                inputRef.current && (inputRef.current.value = "");
                navigate(`/page-search?name=${inputRef.current?.value}`);
              }}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const renderContent = () => {
    return (
      <div className="h-20 flex items-center justify-between">
        <div className="flex items-center lg:hidden flex-1">
          {isAuth() && <MenuBar />}
        </div>

        <div className="lg:flex-1 lg:flex hidden flex items-center">
          <Logo className="flex-shrink-0" />
        </div>

        {
          ecommerceEnabled && (
            <div className="flex-[2] hidden lg:flex justify-center mx-4">
              {renderSearchForm()}
            </div>
          )
        }

        <div>
          {isAuth() && (
            <div className={`flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100`}>
              
              <div className="lg:block md:block hidden pr-1 ml-2">
                <Link
                  to={"/my-points"}
                  className={`flex justify-center items-center  ${getLocalStorage("company")?.isPointsEnabled ? "visible" : "invisible"}`}>
                    <WalletIcon />
                </Link>
                </div>
                
                <Link
                  to={"/my-points"}
                  className={`flex justify-center items-center  ${getLocalStorage("company")?.isPointsEnabled ? "visible" : "invisible"}`}>
                  <div className="text-primary-50  lg:block md:block hidden">
                    {` ${pointsSelector}pts `}
                  </div>
                </Link>
              
              {
                ecommerceEnabled && (
                  <CartDropdown />
                )
              }
              <div className="lg:block md:block hidden">
                {/* <VoucherDropdown /> */}
              </div>
              <AvatarDropdown />
            </div>
          )}
        </div>
        {!isLoginPath() && (
          <div>
            {!isAuth() && (
              <Link
                to={"/login"}
                className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                onClick={() => logout()}
              >
                <div className="ml-4 cursor-pointer">
                  <p className="text-sm font-medium ">{"Login"}</p>
                </div>
              </Link>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container ">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
