import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { sign_up } from "../../network/services/auth";
import { getLocalStorage, setLocalStorage } from "network/helper";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ShowIcon from 'images/visible.svg';
import HideIcon from 'images/blocked.svg';
import { getCompanyID } from "network/services/company";
import NcImage from "shared/NcImage/NcImage";
import { setCompanyStyles } from "utils/setCompanyStyles";

export interface PageSignUpProps {
  className?: string;
}
interface SignUP {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company_id: string;
  isHR: boolean;
  isBulkPurchaseEnabled: boolean;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [values, setValues] = useState<SignUP>({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    company_id: "",
    isHR: false,
    isBulkPurchaseEnabled: false,
  });
  const [companyImage, setCompanyImage] = useState<any>(null)
  const [domains, setDomains] = useState<any>([]);
  const navigate = useNavigate();

  const handleInputChange = (e: any) => {
    let { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const verifyInputs = () => {
    const alpha_regex = new RegExp(/^[A-Za-z]+$/);

    if (values.first_name.length == 0 || !alpha_regex.test(values.first_name)) return "Please Enter Valid First Name"
    if (values.last_name.length == 0 || values.last_name.split(" ").join("").length == 0) return "Please Enter Valid Last Name"

    return null
  }

  const handleSignUp = async (event: any) => {
    event?.preventDefault();
    const error = verifyInputs()
    if (error) {
      toast.error(error)
      return
    }
    if (!validateEmailAgainstDomains()) {
      return toast.error("Please enter Valid Email");
    } else {
      try {
        const response = await sign_up({ ...values, email: values.email.toLowerCase() });
        if (response?.data) {
          toast.success(response?.data?.message, { duration: 3000 });
          navigate("/login");
        } else {
          toast.error(response?.errRes?.data?.message);
        }
      } catch (error) { }
    }
  };

  function validateEmailAgainstDomains() {
    const domain = values.email.substring(values.email.lastIndexOf("@") + 1);
    const allowedDomain = domains.find((allowed: string) => {
      return domain.endsWith(allowed);
    });

    return allowedDomain ? true : false;
  }

  const initCompanyDetails = async () => {
    let obj = {
      hostUrl:
        String(window.location.host).includes("localhost:3000")
          ? process.env.REACT_APP_DEFAULT_COMPANY_URL
          : String(window.location.host).split('/')[0],
    };
    const resp = await getCompanyID(obj)
    if (resp?.data?._id) {
      const company = resp.data
      setLocalStorage("company", company)
      setValues({
        ...values,
        company_id: company._id,
      });
      setDomains(company.allowedDomains);
      setCompanyImage(company.image)
      setCompanyStyles(document, company)
      if (!company.isSelfSignUpEnabled) {
        navigate("/login");
      }

    } else {
      const company = getLocalStorage("company")
      setValues({
        ...values,
        company_id: company._id,
      });
      setDomains(company.allowedDomains);
      if (!company.isSelfSignUpEnabled) {
        navigate("/login");
      }
    }
  }

  useEffect(() => {
    initCompanyDetails()
  }, []);

  return (
    <div className={`nc-PageSignUp  ${className} flex flex-row`} data-nc-id="PageSignUp">
      {
        companyImage && (
          <div className="flex-1 hidden sm:inline-block">
            <NcImage src={companyImage} alt="Company" className="h-full w-full object-fit-cover" containerClassName="h-full w-full" />
          </div>
        )
      }
      <div className="flex-1">
        <Helmet>
          <title>Sign up </title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <img
            className="my-10 mx-auto flex items-center w-25 h-14"
            src={getLocalStorage("company").logo}>
          </img>
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Sign up
          </h2>
          <div className="max-w-md mx-auto space-y-6 ">
            {/* FORM */}
            <form
              onSubmit={(e: any) => handleSignUp(e)}
              className="grid grid-cols-1 gap-6"
            >
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  First name
                </span>
                <Input
                  onChange={handleInputChange}
                  type="text"
                  name="first_name"
                  placeholder="Enter First name"
                  className="mt-1"
                />
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Last Name
                </span>
                <Input
                  type="text"
                  onChange={handleInputChange}
                  name="last_name"
                  placeholder="Enter Last name"
                  className="mt-1"
                />
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Company email address
                </span>
                <Input
                  type="email"
                  onChange={handleInputChange}
                  name="email"
                  placeholder="example@example.com"
                  className="mt-1"
                />
              </label>
              <label className="block relative">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                </span>
                <Input
                  onChange={handleInputChange}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className="mt-1"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-0 -translate-x-[50%] bottom-0 translate-y-[-50%]"
                >
                  <img src={showPassword ? HideIcon : ShowIcon} />
                </button>
              </label>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </form>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              Already have an account? {` `}
              <Link className="text-green-600" to="/login">
                Sign in
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
