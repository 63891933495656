import React, { ChangeEventHandler, FC, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { createCatalogue, editCatalogueName } from "network/services/HR";
import toast from "react-hot-toast";

export interface ProblemPlan {
  name: string;
  id: string;
  label: string;
}

export interface ModalReportItemProps {
  show: boolean;
  problemPlans?: ProblemPlan[];
  onCloseModalReportItem: () => void;
  isEdit?: boolean;
  editData?: any;
}

const problemPlansDemo = [
  { name: "Violence", id: "Violence", label: "Violence" },
  { name: "Trouble", id: "Trouble", label: "Trouble" },
  { name: "Spam", id: "Spam", label: "Spam" },
  { name: "Other", id: "Other", label: "Other" },
];

const CatalogueCreateModal: FC<ModalReportItemProps> = ({
  problemPlans = problemPlansDemo,
  show,
  onCloseModalReportItem,
  isEdit,
  editData,
}) => {
  const textareaRef = useRef(null);

  const [value, setValues] = useState({name: ""});
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    if (isEdit) {
      setValues({ ...value, name: editData?.name });
    }
  }, [show]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if(disable) return
    const regex = /^[A-Za-z]+$/
    if(regex.test(e.target.value) || e.target.value === "")
      setValues((state: any) => ({...state, name: e.target.value.trim()}))
  }

  const handleClickSubmitForm = async () => {
    if(disable) return
    if(!value.name || value.name == "") {
      toast.error("Please Enter A Valid Catalog Name")
      return
    }
    
    let obj = {
      name: value?.name,
    };
    try {
      let response;
      if (isEdit) {
        response = await editCatalogueName(obj, editData?._id);
      } else {
        response = await createCatalogue(obj);
      }

      if (response?.data.message === "Catalog created" || response?.data.message === "Catalog renamed successfully") {
        setDisable(true)
        toast.success(response?.data?.message, {
          duration: 2000
        });
        
        setTimeout(() => {
          setDisable(false)
          onCloseModalReportItem();
        }, 2000)
      } else {
        toast.error(response?.data?.message);
      }

      setValues({name: ""})
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const renderContent = () => {
    return (
      <>
        <div className="mt-4">
          <div>
            <Label className="text-sm">Catalog Name</Label>
            <Input
              onChange={handleChange}
              className="mt-1.5"
              placeholder="Enter Catalog Name"
              // name="catalogue_name"
              // id="catalogue_name"
              value={value?.name}
            />
          </div>
        </div>
        <div className="mt-4 space-x-3">
          <ButtonPrimary onClick={handleClickSubmitForm} type="submit">
            Submit
          </ButtonPrimary>
          <ButtonSecondary type="button" onClick={() => {
            setValues(state => ({...state, name: ""}))
            onCloseModalReportItem()
          }}>
            Cancel
          </ButtonSecondary>
        </div>
      </>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalReportItem}
      contentExtraClass="max-w-screen-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle={(isEdit)?"Edit Catalog":"Create a Catalog"}
    />
  );
};

export default CatalogueCreateModal;
