import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import { getLocalStorage, isAuth } from "network/helper";
import LoginNotify from "./LoginNotify";
import { ADD_CART_ACTION, UPDATE_CART_ACTION } from "../redux/actions/cart";
import {
  ADD_WISH_LIST_ACTION,
  DELETE_WISH_LIST_ACTION,
} from "../redux/actions/wishlist";
import { ADD_MODAL_VIEW_PRODUCT_ACTION } from "../redux/actions/productModalView";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserCartDetails,
  addUserWishListDetails,
  deleteUserWishListDetails,
  updateUserCartDetails,
} from "network/services/account";
import LikeButton from "./LikeButton";
import Skeleton from "react-loading-skeleton";

export interface ProductCardProps {
  className?: string;
  data?: any;
  isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
  isLiked,
}) => {
  const {
    name,
    currentPrice,
    description,
    entitiesList,
    image,
    images,
    defaultImage,
    _id,
    isEnabledForPoints
  } = data;
  const dispatch = useDispatch();
  const cartSelector: any = useSelector((state: any) => state?.CART);
  const WishListSelector: any = useSelector((state: any) => state?.Wish__list);
  const [variantActive, setVariantActive] = React.useState(0);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const handleModalClose = () => {
    setShowModalQuickView(false);
  };

  const handleModalOpen = () => {
    setShowModalQuickView(true);
    dispatch(ADD_MODAL_VIEW_PRODUCT_ACTION(data));
  };

  // const updateCart = async (item_id: any, entity: string) => {
  //   let obj = {
  //     product: item_id,
  //     entity: entity,
  //     quantity: 1,
  //   };

  //   try {
  //     const response = await updateUserCartDetails(obj);

  //     if (response?.data) {
  //       dispatch(UPDATE_CART_ACTION(obj));
  //     }
  //   } catch (error) {}
  // };

  // const notifyAddToCart = async (data: any, entity_id: any) => {
  //   let obj = {
  //     product: data,
  //     quantity: 1,
  //     entity: entity_id,
  //   };
  //   const isAlreadyInCart = cartSelector.some(
  //     (cartItem: any) => cartItem.product?._id === data._id
  //   );

  //   if (isAlreadyInCart) {
  //     const isEntityUpdate = cartSelector.some(
  //       (cartItem: any) =>
  //         cartItem.product?._id === data._id && cartItem.entity !== entity_id
  //     );
  //     if (isEntityUpdate) {
  //       updateCart(data?._id, entity_id);
  //       toast.success("Size Updated");
  //     } else {
  //       toast.custom(
  //         (t) => (
  //           <Transition
  //             appear
  //             show={t.visible}
  //             className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
  //             enter="transition-all duration-150"
  //             enterFrom="opacity-0 translate-x-20"
  //             enterTo="opacity-100 translate-x-0"
  //             leave="transition-all duration-150"
  //             leaveFrom="opacity-100 translate-x-0"
  //             leaveTo="opacity-0 translate-x-20"
  //           >
  //             <p className="block text-base font-semibold leading-none">
  //               Item Exist
  //             </p>
  //           </Transition>
  //         ),
  //         { position: "top-right", id: "nc-product-notify", duration: 1000 }
  //       );
  //     }
  //   } else {
  //     try {
  //       let obj2 = {
  //         product_id: data?._id,
  //         quantity: 1,
  //         entity: entity_id,
  //       };

  //       const resp = await addUserCartDetails(obj2);
  //       if (resp?.data) {
  //         dispatch(ADD_CART_ACTION([{...obj, _id: resp.data._id}]));
  //         toast.custom(
  //           (t) => (
  //             <Transition
  //               appear
  //               show={t.visible}
  //               className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
  //               enter="transition-all duration-150"
  //               enterFrom="opacity-0 translate-x-20"
  //               enterTo="opacity-100 translate-x-0"
  //               leave="transition-all duration-150"
  //               leaveFrom="opacity-100 translate-x-0"
  //               leaveTo="opacity-0 translate-x-20"
  //             >
  //               <p className="block text-base font-semibold leading-none">
  //                 Added to cart!
  //               </p>
  //               <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
  //               {renderProductCartOnNotify(data, obj.entity)}
  //             </Transition>
  //           ),
  //           { position: "top-right", id: "nc-product-notify", duration: 2000 }
  //         );
  //       }
  //     } catch (error) {}
  //   }
  // };

  // const renderProductCartOnNotify = (data: any, entity: any) => {
  //   return (
  //     <div className="flex ">
  //       <div className="ml-4 flex flex-1 flex-col">
  //         <div>
  //           <div className="flex justify-between ">
  //             <div className="h-24 w-[20%] flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
  //               <img
  //                 src={data?.images[data?.defaultImage - 1]}
  //                 alt={name}
  //                 className="h-full w-full object-contain object-fill"
  //               />
  //             </div>
  //             <div className="w-[50%]">
  //               <h3 className="text-base font-medium ">{data?.name}</h3>
  //               <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
  //                 <span> Size : {entity.name || "S"}</span>
  //               </p>
  //               <p className="text-gray-500 dark:text-slate-400">Qty : 1</p>
  //             </div>
  //             <Prices price={data?.currentPrice} className="mt-0.5" />
  //           </div>
  //         </div>
  //         <div className="flex flex-1 pt-3 items-end justify-between text-sm">
  //           <div className="flex">
  //             <Link
  //               to={"/cart"}
  //               className="font-medium text-primary-6000 dark:text-primary-500 "
  //             >
  //               View cart
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderGroupButtons = (item: any) => {
    return (
      <div className="inset-x-1 flex justify-center transition-all">
        <ButtonSecondary
          className="ml-1.5 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={handleModalOpen}
        >
          <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
          <span className="ml-1">Quick view</span>
        </ButtonSecondary>
      </div>
    );
  };

  // const renderSizeList = () => {
  //   if (!entitiesList || !entitiesList.length) {
  //     return null;
  //   }

  //   return (
  //     <div className="relative bottom-0 inset-x-1 space-x-1.5 flex gap-y-5 gap-2 transition-all z-10 flex-wrap">
  //       {entitiesList.map((size: any, index: any) => {
  //         return (
  //           <div
  //             key={index}
  //             className="min-w-16 px-2 nc-shadow-lg h-10 rounded-xl bg-white hover:bg-slate-900 hover:text-white transition-colors cursor-pointer flex items-center justify-center uppercase font-semibold tracking-tight text-sm text-slate-900 flex-wrap"
  //           >
  //             {size?.name}
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const handleAddWishList = async (id: string) => {
    let obj = {
      product: id,
    };
    try {
      const resp = await addUserWishListDetails(obj);
      if (resp?.data?.message === "Product added to wishlist") {
        dispatch(ADD_WISH_LIST_ACTION(id));
        toast.success("Added to save list");
      } else {
        toast.error(resp?.data?.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const handleDeleteWishList = async (id: string) => {
    try {
      const resp = await deleteUserWishListDetails(id);
      if (resp?.data?.message == "Product deleted") {
        dispatch(DELETE_WISH_LIST_ACTION(id));
        toast.error("removed from save list");
      } else {
        toast.error(resp?.data?.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      {data ? (
        <div
          className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
          data-nc-id="ProductCard"
        >
          <Link
            to={`/product-detail?id=${_id}`}
            className="absolute inset-0"
          ></Link>

          <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
            <Link to={`/product-detail?id=${_id}`} className="block">
              <NcImage
                containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
                src={Array.isArray(images) ? images[defaultImage - 1] : image}
                className="object-cover w-full h-full drop-shadow-xl"
              />
            </Link>

            {/* <ProductStatus status={status} /> */}

            <LikeButton
              onClick={() =>
                WishListSelector && WishListSelector.includes(_id)
                  ? handleDeleteWishList(_id)
                  : handleAddWishList(_id)
              }
              liked={WishListSelector && WishListSelector.includes(_id)}
              className="absolute top-3 right-3 z-10"
            />
          </div>

          <div className="space-y-4 px-2.5 pt-5 pb-2.5">
            {/* {renderVariants()} */}
            {renderGroupButtons(data)}

            <div>
              <h2
                className={`nc-ProductCard__title text-base font-semibold transition-colors`}
              >
                {name}
              </h2>
              <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 h-[40px] overflow-hidden `}>
                {description}
              </p>
            </div>

            <div className="flex justify-between items-end ">
              <Prices price={currentPrice} />
            </div>
            {getLocalStorage("company")?.isPointsEnabled && !!isEnabledForPoints && (
              <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}>
                or claim by{" "}
                <span className="font-medium text-primary-6000 dark:text-primary-500 ">
                  {" "}
                  {currentPrice} points{" "}
                </span>
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="md:w-[300px] w-[250px] ">
          <Skeleton className="md:h-[250px] h-[200px]  rounded-3xl " />
          <Skeleton height={"30px"} />
          <Skeleton />
          <Skeleton height={"30px"} width={"30%"} />
        </div>
      )}

      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={handleModalClose}
      />
    </>
  );
};

export default ProductCard;
