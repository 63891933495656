import { Helmet } from "react-helmet-async";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DynamicTable from "components/CustomTable";
import Pagination from "shared/Pagination/Pagination";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, Fragment } from "react";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import Heading from "components/Heading/Heading";
import { Link } from "react-router-dom";
import Heading2 from "components/Heading/Heading2";
import { listAllPointsByUserId } from "network/services/HR";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

const HrDashboard = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const employeeData = location.state?.rowData;
    if(!employeeData) navigate("/hr-points")
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [tableHeaders, setTableHeader] = useState([
        { name: "Slno", key: "slno" },
        { name: "Credited Points", key: "amount" },
        { name: "Available Points", key: "availableAmount" },
        { name: "Description", key: "description" },
        { name: "Credited on", key: "createdAt" },
        { name: "Expires On", key: "expiryDate" },
    ]);
    const [tableHeaders2, setTableHeader2] = useState([
        { name: "Slno", key: "slno" },
        { name: "Used points", key: "amount" },
        { name: "Used On", key: "usedDate" },
    ]);

    interface PointsCreditedItem {
        slno: number;
        id: string;
        amount: number;
        availableAmount: number;
        expiryDate: String;
        shouldExpire: boolean;
        createdDate: String;
        description: string;
        companyId: string;
        userId: string;
        createdAt: String;
        updatedAt: String;
    }

    interface PointsUsedItem {
        slno: number;
        id: string;
        amount: number;
        usedDate: String;
        companyId: string;
        userId: string;
        createdAt: String;
        updatedAt: String;
    }

    const [creditedPointsTableData, setCreditedPointsTableData] = useState<PointsCreditedItem[]>([]);
    const [debitedPointsTableData, setDebitedPointsTableData] = useState<PointsUsedItem[]>([]);
    const [availablePoints, setAvailablePoints] = useState<number>(0)
    

    const getAllPointsByUserId = async (id: string) => {
        try {
            const resp = await listAllPointsByUserId(id);
            
            if (resp?.data) {
                let creditedCount = 1; // Initialize count variable
                let debitedCount = 1; // Initialize count variable
                // Assuming resp.data is an object with properties 'credits' and 'debits'
                console.log(resp.data)
                const pointsCreditedItem: PointsCreditedItem[] = resp.data.credits.map((credit: any) => ({
                    slno: creditedCount++,
                    id: credit._id,
                    amount: credit.amount,
                    availableAmount: credit.availableAmount,
                    expiryDate: ConvertDateObjToHumanReadableDateString(credit.expiryDate),
                    shouldExpire: credit.shouldExpire,
                    createdDate: ConvertDateObjToHumanReadableDateString(credit.createdDate),
                    description: credit.description,
                    companyId: credit.companyId,
                    userId: credit.userId,
                    createdAt: ConvertDateObjToHumanReadableDateString(credit.createdAt),
                    updatedAt: ConvertDateObjToHumanReadableDateString(credit.updatedAt)
                }));
    
                const pointsUsedItem: PointsUsedItem[] = resp.data.debits.map((debit: any) => ({
                    slno: debitedCount++, // Incrementing count for unique slno
                    id: debit._id,
                    amount: debit.amount,
                    usedDate: ConvertDateObjToHumanReadableDateString(debit.usedDate),
                    companyId: debit.companyId,
                    userId: debit.userId,
                    createdAt: ConvertDateObjToHumanReadableDateString(debit.createdAt),
                    updatedAt: ConvertDateObjToHumanReadableDateString(debit.updatedAt)
                }));
                setCreditedPointsTableData(pointsCreditedItem);
                setDebitedPointsTableData(pointsUsedItem);
                setAvailablePoints(resp.data.totalAvailablePoints)
            } else {
                toast.error(resp?.data.message);
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while fetching points data.");
        }
    };
    
    const ConvertDateObjToHumanReadableDateString = (dateStr: string) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
    }
    
      useEffect(()=>{

        const response = getAllPointsByUserId(id!);
      },[])

    return !!employeeData?(
        <div className="nc-PageHome  relative overflow-hidden">
            <Helmet>
                <title>Hr Points</title>
            </Helmet>

            <div className="container">
                <div className="mb-12 sm:mb-16">
                    <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                        <Link to={"/hr-dashboard"} className="">
                            My Menu
                        </Link>
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        <Link to={"/hr-points"} className="">
                            Points Credited
                        </Link>
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        <span className="underline">{employeeData && employeeData.email?employeeData.email: ""}</span>
                    </div>
                </div>
                <Heading2
                    className="mb-1 lg:mb-1 text-xl text-neutral-900 dark:text-neutral-50 nc-p-r-container "
                    heading="Employee Details : "
                    subHeading=" "
                />
                <div className=" nc-shadow-lg transition-shadow  border-slate md:w-[45%] px-6 py-4 lg:w[40%] sm:w-full  lg:ml-12 ml-0 min-h-[150px] m-6 ">
                    <table className="w-full">
                        <tr className="border border-slate ">
                            <td className="font-semibold border border-slate p-2">Name:</td>
                            <td className="text-xl border p-2 border-slate">{employeeData.name}</td>
                        </tr>
                        <tr className="border border-slate">
                            <td className="font-semibold border border-slate  p-2">Email:</td>
                            <td className="text-xl p-2 border border-slate">{employeeData && employeeData.email? employeeData.email: ""}</td>
                        </tr>
                        <tr className="border border-slate">
                            <td className="font-semibold border border-slate  p-2">Available Active Points:</td>
                            <td className="text-xl  p-2 border border-slate">{availablePoints} points</td>
                        </tr>
                    </table>

                </div>
                {
                    creditedPointsTableData.length > 0 && (
                        <Heading2
                            className="mb-1 lg:mb-1 text-xl text-neutral-900 dark:text-neutral-50 nc-p-r-container "
                            heading="Points Credited "
                            subHeading=" "
                        />
                    )
                }
                {
                    creditedPointsTableData.length > 0 && (
                        <div className="mt-6  lg:ml-12 ml-0 m-6  rounded mb-24 lg:mt-4 border border-slate ">
                            <div className="bg-white rounded ">
                                <div className=" flex items-center justify-between pb-4 m-4 pt-4">
                                    <div className="flex w-full  mr-5 items-center">
                                    </div>
                                </div>
                                <DynamicTable
                                    path="/hr-vouchers-details"
                                    tableData={creditedPointsTableData}
                                    tableHeader={tableHeaders}
                                    isCLickable={false}
                                />
                                <div className=" pb-4 m-4 pt-4 flex flex-col mt-12 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    debitedPointsTableData.length > 0 && (
                        <Heading2
                            className="mb-1 lg:mb-1 text-xl text-neutral-900 dark:text-neutral-50 nc-p-r-container "
                            heading="Points Used "
                            subHeading=" "
                        />
                    )
                }
                {
                    debitedPointsTableData.length > 0 && (
                        <div className="mt-6  lg:ml-12 ml-0 m-6  rounded mb-24 lg:mt-4 border border-slate ">
                            <div className="bg-white rounded ">
                                <div className=" flex items-center justify-between pb-4 m-4 pt-4">
                                    <div className="flex w-full  mr-5 items-center">
                                    </div>
                                </div>
                                <DynamicTable
                                    path="/hr-vouchers-details"
                                    tableData={debitedPointsTableData}
                                    tableHeader={tableHeaders2}
                                    isCLickable={false}
                                />
                                <div className=" pb-4 m-4 pt-4 flex flex-col mt-12 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    ):null;
};

export default HrDashboard;
