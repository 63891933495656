import { ChangeEventHandler, FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Label from "components/Label/Label";
import countries from "utils/getCountries";
import { getStates } from "network/services/getStates";
import { toast } from "react-hot-toast";


interface AddressFormProps {
    value: {
        firstName: string,
        lastName: string,
        email: string,
        altContact: string,
        phoneNo: string,
        address: string,
        country: string,
        city: string,
        district: string,
        state: string,
        pincode: string,
    },
    onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>,
}

const AddressForm: FC<AddressFormProps> = ({
    value = {
        firstName: "",
        lastName: "",
        altContact: "",
        phoneNo: "",
        address: "",
        country: "",
        city: "",
        state: "",
        pincode: "",
        district: "",
    },
    onChange = (e) => {},
}) => {
  const [states, setStates] = useState<Array<string>>([])

  const fetchStates = async () => {
    const res = await getStates()
    if(res.data && Array.isArray(res.data)) {
      setStates(res.data)
    } else {
      toast.error("Error while loading states, please refresh the page")
    }
  }

  useEffect(() => {
    fetchStates()
  }, [])


    return (
        <form>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">First name *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your firsrt name"
                type="text"
                value={value.firstName}
                name="firstName"
                onChange={onChange}
              />
            </div>
            <div>
              <Label className="text-sm">Last name *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your last name"
                type="text"
                name="lastName"
                value={value.lastName}
                onChange={onChange}
              />
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Phone Number *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your phone number"
                type="number"
                name="phoneNo"
                maxLength={10}
                value={value.phoneNo}
                onChange={onChange}
              />
            </div>
            <div>
              <Label className="text-sm">Alt Contact No</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter alternate phone number"
                type="number"
                name="altContact"
                maxLength={10}
                value={value.altContact}
                onChange={onChange}
              />
            </div>
          </div>
          {/* <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Email *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your email id"
                type="email"
                name="email"
                value={value.email}
                onChange={onChange}
              />
            </div>
          </div> */}


          {/* ============ */}
          <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
            <div className="flex-1">
              <Label className="text-sm">Address *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter delivery address"
                type={"text"}
                name="address"
                value={value.address}
                onChange={onChange}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm">Country *</Label>
              <Select name="country" value={value.country} onChange={onChange} className="mt-1.5">
                <option value="">Select a country</option>
                {
                  countries.map((country: string, index: number) => {
                    return (
                      <option value={country} key={index}>{country}</option>
                    )
                  })
                }
              </Select>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
          <div>
              <Label className="text-sm">State *</Label>
              <Select name="state" value={value.state} onChange={onChange} className="mt-1.5">
                <option value="">Select a state</option>
                {
                  states.map((state: string, index: number) => {
                    return (
                      <option value={state} key={index}>{state}</option>
                    )
                  })
                }
              </Select>
            </div>
            <div>
              <Label className="text-sm">District</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your district"
                type="text"
                name="district"
                value={value.district}
                onChange={onChange}
              />
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">City *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your city"
                type="text"
                name="city"
                value={value.city}
                onChange={onChange}
              />
            </div>
            <div>
              <Label className="text-sm">Postal code *</Label>
              <Input
                className="mt-1.5"
                placeholder="Enter your postal code"
                maxLength={6}
                type="number"
                name="pincode"
                value={value.pincode}
                onChange={onChange}
              />
            </div>
          </div>
        </form>
    )
}

export default AddressForm