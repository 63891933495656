import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ProductCard from "components/ProductCard";
import TabFilters from "./TabFilters";
import { getProducts, getFilteredProducts } from "network/services/product";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

export interface PageCollectionProps {
  className?: string;
}

const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {
  const [categoryProductData, setCategoryProductData] = useState<any>([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [subCatState, setSubCatState] = useState("");
  const [staticProd, setStaticProd] = useState<any>();

  const getCategoryBasedProduct = async () => {
    try {
      const response: any = await getProducts(searchParams.get("id"));
      if (response?.data && Array.isArray(response.data)) {
        let temp = [];
        temp = response?.data;
        setCategoryProductData(temp);
        setStaticProd(temp);
      } else {
        toast.error(response.data.message)
      }
    } catch (error: any) {
      toast.error(error.message)
    }
  };

  const filterSubCatProduct = async (range: any, subCatId: any) => {
    if (range && subCatId) {
      let filteredArray = staticProd.filter(
        (obj: any) =>
          obj.currentPrice >= range[0] &&
          obj.currentPrice <= range[1] &&
          obj.subcategory === subCatId
      );
      setCategoryProductData(filteredArray);
    } else if (range) {
      let filteredArray = staticProd.filter(
        (obj: any) =>
          obj.currentPrice >= range[0] && obj.currentPrice <= range[1]
      );
      setCategoryProductData(filteredArray);
    }
    else{
      setCategoryProductData(staticProd)
    }
  };

  useEffect(() => {
    getCategoryBasedProduct();
  }, [location]);

  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>{searchParams.get("name")}</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              {searchParams.get("name")?.toLocaleUpperCase()}
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people.
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* TABS FILTER */}
            <TabFilters
              catID={searchParams.get("id")}
              filterSubCat={(range: any, id: string) =>
                filterSubCatProduct(range, id)
              }
            />

            {/* LOOP ITEMS */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
              {categoryProductData.length >= 1 &&
                categoryProductData.map((item: any, index: any) => (
                  <ProductCard data={item} key={index} />
                ))}
            </div>

            {/* PAGINATION */}
            {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <Pagination />
            </div> */}
          </main>
        </div>
      </div>
    </div>
  );
};

export default PageCollection;
