import React, { FC, useState } from "react";
import imageRightPng from "images/11.png";
import imageRightPng2 from "images/11.png";
import imageRightPng3 from "images/11.png";

import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";

interface Hero2DataType {
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
  data?: any;
}
export interface SectionHero2Props {
  className?: string;
  data?: any;
}

let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero2: FC<SectionHero2Props> = ({ className = "", data }) => {
  // =================
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);

  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
  );
  //

  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (!data) return 0;
      if (state >= data.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= data.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return data.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };

  return (
    <div>
      {data && Array.isArray(data) && data.map((item: any, index: number) => {
        const isActive = indexActive === index;
        if (!isActive) {
          return null;
        }
        return (
          <div
            style={{ height: "50vh" }}
            className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
            key={index}
          >
            <div className="absolute h-70  bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
              {data && data.map((item: any, ind: any) => {
                const isActive = indexActive === ind;
                return (
                  <div
                    key={ind}
                    onClick={() => {
                      setIndexActive(ind);
                      handleAfterClick();
                    }}
                    className={`relative px-1 py-1.5 cursor-pointer`}
                  >
                    <div
                      className={`relative p-1 h-1 shadow-sm rounded-md bg-white`}
                    >
                      {isActive && (
                        <div
                          className={` absolute inset-0 bg-slate-900 rounded-md ${isActive ? " " : " "
                            }`}
                        ></div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <Prev
              className="absolute left-1 sm:left-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
              btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
              svgSize="w-6 h-6"
              onClickPrev={handleClickPrev}
            />
            <Next
              className="absolute right-1 sm:right-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
              btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
              svgSize="w-6 h-6"
              onClickNext={handleClickNext}
            />

            {/* BG */}
            <div className="absolute h-100 inset-0 bg-[#E3FFE6]">
              {/* <div className="absolute inset-0 bg-[#F7F0EA]"> */}
              <img
                className="absolute w-full h-full object-fill"
                src={item}
                alt="hero"
              />
            </div>

            <div className="relative container h-[700] pb-0 pt-14 sm:pt-20 lg:py-44">
              <div className="mt-10 lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full max-w-2xl xl:max-w-3xl 2xl:max-w-4xl"></div>
            </div>
          </div>
        );
      })}
    </div>
  )
};

export default SectionHero2;
