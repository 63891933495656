import React, { FC, useState } from "react";
import imageRightPng from "images/11.png";
import imageRightPng2 from "images/11.png";
import imageRightPng3 from "images/11.png";

import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import NcImage from "shared/NcImage/NcImage";

interface Hero2DataType {
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
}
export interface ProductImageCarousel {
  className?: string;
  DATA?:any
}


let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero2: FC<ProductImageCarousel> = ({ className = "" ,DATA = []}) => {
  // =================
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);

  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
  );

  //

  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= DATA.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= DATA.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return DATA.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 100000);
  };
  // =================

  // return <>{DATA.map((_: any, index: number) => renderItem(index))}</>;
  return (
    <div>
      {
        DATA && Array.isArray(DATA) && DATA.map((item: any, index: number) => {
          const isActive = indexActive === index;
          if (!isActive) {
            return null;
          }
          return (
            <div
            style={{height:'75%',minHeight:'40vh'}}
              className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col lg:flex-col relative overflow-hidden ${className}`}
              key={index}
            >


              <Prev
                className="absolute left-1 sm:left-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickPrev={handleClickPrev}
              />
              <Next
                className="absolute right-1 sm:right-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickNext={handleClickNext}
              />

              {/* BG */}
              <div className="absolute inset-0">
                <NcImage
                  className=" fluid absolute w-full h-full object-contain"
                  src={item}
                  alt="hero"
                />
              </div>

              <div style={{height:'100%'}} className="relative container  pb-0 pt-14 sm:pt-20 lg:py-44">
                <div className="mt-10 lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
            
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  )
};

export default SectionHero2;
