import React, { FC, useEffect, useState } from "react";
import HeaderFilterSection from "components/HeaderFilterSection";
import ProductCard from "components/ProductCard";
import { Product, PRODUCTS } from "data/data";
import { useSelector } from "react-redux";
import { getProducts } from "network/services/product";

export interface SectionGridFeatureItemsProps {
  data?: Product[];
}

const SectionGridFeatureItems: FC<SectionGridFeatureItemsProps> = ({
  data = PRODUCTS,
}) => {
  const categorySelector: any = useSelector<any>((state) => state?.Category);
  const [categoryData, setCategoryData] = useState<any>([]);
  const [categoryProductData, setCategoryProductData] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getCategoryBasedProduct = async (id: string) => {
    setLoading(true);
    try {
      const response: any = await getProducts(id);
      if (response?.data) {
        const products = Array.isArray(response.data) ? response.data : [];
        const trendingProducts = products.filter((item: any) => item.isTrending === true);
        const sortedProduct = trendingProducts.sort((a: any, b: any) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });
        setCategoryProductData(sortedProduct);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (categorySelector?.length) {
      setCategoryData(categorySelector);
      getCategoryBasedProduct(categorySelector[0]?._id);
    }
  }, [categorySelector]);

  return (
    <div className="nc-SectionGridFeatureItems relative">
      <HeaderFilterSection
        onFilterChange={(id) => getCategoryBasedProduct(id)}
        navItemFilter={categoryData}
      />
      <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {loading ? (
          <div style={{ height: '200px' }} className="flex items-center justify-center">
            <span>Loading...</span>
          </div>
        ) : categoryProductData.length ? (
          categoryProductData.map((item: Product, index: number) => (
            <ProductCard data={item} key={index} />
          ))
        ) : (
          <div className="flex items-center justify-center">
            <span>No products available</span>
          </div>
        )}
      </div>
      <div className="flex mt-16 justify-center items-center">
        {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
      </div>
    </div>
  );
};

export default SectionGridFeatureItems;
