import { FC, useState } from 'react';
import VoucherCard from './VoucherCard';
import VoucherPopup from './VoucherPopup';

export interface ProductSelectionProps {
  data: Array<{category: string, products: Array<Object>, selected: {product: string, entity: string | null}}>,
  handleSelectProduct: (categoryIndex: number, productId: string, entityId: string | null) => void,
}

const ProductSelectionSection: FC<ProductSelectionProps> = ({data, handleSelectProduct}) => {
  const [showPopup, setShowPopup] = useState(false)
  const [popupData, setPopupData] = useState<any | null>(null)

  const handleShowPopup = (categoryIndex: number, productIndex: number) => {
    setPopupData({
      categoryIndex,
      productIndex,
      product: data[categoryIndex].products[productIndex]
    })
    // setPopupProduct(data[categoryIndex].products[productIndex])
    setShowPopup(true)
  }

  return (
    <div className="flex flex-col justify-start gap-20">
      {
        showPopup && (
          <VoucherPopup 
            show={showPopup}
            data={popupData}
            closePopup={() => setShowPopup(false)}
            selectProduct={handleSelectProduct}
          />
        )
      }
      {
        data && Array.isArray(data) && data.map((section: {category: string, selected: {product: string, entity: string| null}, products: Array<Object>}, categoryIndex: number) => {
          return (section.products.length > 0) ? (
            <div key={categoryIndex}>
              <h3 className="text-2xl font-semibold text-gray-500">{section.category}</h3>
              <div>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                  {section.products.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        <VoucherCard 
                          data={{...item, image: item.images[0], id: item._id}} 
                          showPopup={() => handleShowPopup(categoryIndex, index)}
                          onClick={(entity: string | null) => handleSelectProduct(categoryIndex, item._id, entity)}
                          isSelected={item._id == section.selected.product}
                          selectedEntity={item._id == section.selected.product? section.selected.entity: null}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ): null;
        })
      }
    </div>
  )
}

export default ProductSelectionSection;