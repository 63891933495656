import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import CategoryPage from "containers/CategoryPage";
import { useEffect, useState } from "react";
import { getLocalStorage, isAuth } from "network/helper";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ADD_CATEGORY_ACTION } from "./../../redux/actions/category";
import { ADD_CART_ACTION } from "./../../redux/actions/cart";
import {
  getListCategory as getCategoryFromAPI,
  getProducts,
} from "network/services/product";
import { useNavigate } from "react-router-dom";
import { getBanners } from "network/services/company";
import {
  getUserCartDetails,
  getUserVouchers,
  getUserWishListDetails,
} from "network/services/account/index";
import { ADD_WISH_LIST_ACTION } from "./../../redux/actions/wishlist";
import Skeleton from "react-loading-skeleton";
import { ADD_PRODUCT_ACTION } from "./../../redux/actions/product";
import Heading from "components/Heading/Heading";

function PageHome() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState<any>();
  const [banner, setBanners] = useState<any>([]);
  const [vouchers, setVouchers] = useState<Array<any>>([]);

  const getCategoryList = async () => {
    try {
      const response: any = await getCategoryFromAPI();
      if (response?.data) {
        dispatch(ADD_CATEGORY_ACTION(response?.data));
        let temp = [];
        temp = response?.data;
        setCategoryData(temp);
        getCategoryBasedProduct(response?.data[0]?._id);
      } else {
      }
    } catch (error) { }
  };

  const getCategoryBasedProduct = async (id: string) => {
    try {
      const response: any = await getProducts(id);
      if (response?.data) {
        return response?.data;
      } else {
      }
    } catch (error) { }
  };

  const getBannerFromAPI = async () => {
    try {
      const response: any = await getBanners();
      if (response?.data) {
        setBanners([...response?.data]);
      }
    } catch (error) { }
  };

  const getWishListDetailsFromAPI = async () => {
    try {
      const response = await getUserWishListDetails();
      if (response?.data) {
        response?.data.map((item: any) => {
          dispatch(ADD_WISH_LIST_ACTION(item?.product?._id));
        });
      }
    } catch (error) { }
  };
  const getAllProduct = async () => {
    try {
      const response: any = await getProducts("");
      if (response?.data) {
        dispatch(ADD_PRODUCT_ACTION(response?.data));
      }
    } catch (error) { }
  };

  const fetchVouchers = async () => {
    const res = await getUserVouchers()
    if (res.data) {
      res.data.reverse()
      setVouchers(res.data)
    }
  }


  useEffect(() => {
    // getCartDetailsFromAPI();
    getBannerFromAPI();
    getCategoryList();
    getWishListDetailsFromAPI();
    getAllProduct();
    fetchVouchers()
  }, []);

  useEffect(() => {
    if (!isAuth()) {
      navigate("/");
    }
  }, [location]);

  const [renderedData, setRenderedData] = useState<any[]>([]);

  useEffect(() => {
    if (categoryData) {
      Promise.all(
        categoryData.map(async (item: any) => {
          let data = await getCategoryBasedProduct(item?._id);
          const products = Array.isArray(data) ? data : [];
          const trendingProducts = products.filter((item: any) => item.isHomeShelf === true);
          return (
            trendingProducts.length >= 1 && (
              <SectionSliderProductCard
                key={item?._id} // Don't forget to add a unique key when rendering a list of elements
                subHeading={item?.description}
                categoryName={`${item?.name.charAt(0).toUpperCase()}${item?.name
                  .slice(1)
                  .toLocaleLowerCase()}`}
                data={trendingProducts}
              />
            )
          );
        })
      ).then((resolvedData) => {
        setRenderedData(resolvedData);
      });
    }
  }, [categoryData]);

  const [eCommerceEnabledStatus, seteCommerceEnabledStatus] = useState<Boolean>(false);
  useEffect(() => {
    const companyDetails = window.localStorage.getItem('company')
    const formattedCompanyDetails = JSON.parse(String(companyDetails))

    if (formattedCompanyDetails?.isEcommerceEnabled) {
      seteCommerceEnabledStatus(formattedCompanyDetails?.isEcommerceEnabled)
    }
  });

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* SECTION HERO */}
      {banner?.length >= 1 ? (
        <SectionHero2 data={banner} />
      ) : (
        <Skeleton className="md:h-[550px] h-[450px]  " />
      )}
      {getLocalStorage("company")?.isRedeemEnabled && Array.isArray(vouchers) && vouchers.length > 0 && (
        <div className="mt-24 lg:mt-32">
          <DiscoverMoreSlider vouchers={vouchers} />
        </div>
      )}

      {eCommerceEnabledStatus && categoryData && (
        <Heading
          className="container mb-12 text-neutral-900 dark:text-neutral-50 text-left translate-y-[60px] mt-20"
          desc=""
          rightDescText="Great products are waiting for you"
        >
          Shop Now
        </Heading>
      )}
      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION */}
        {eCommerceEnabledStatus && renderedData}
        {eCommerceEnabledStatus && categoryData && <SectionGridFeatureItems />}
        {eCommerceEnabledStatus && categoryData && <CategoryPage />}
      </div>
    </div>
  );
}

export default PageHome;
