import Label from "components/Label/Label";
import { deleteUserAddress, getUserAddresses, addUserAddress, updateUserAddress } from "network/services/account";
import React, { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import EditIcon from 'images/edit_icon.svg';
import CancelIcon from 'images/close_icon.svg';
import AddressForm from "./AddressForm";
import { ChangeEventHandler } from "react";
import { verifyAddress } from "utils/verifyAddress";
import { getLocalStorage } from "network/helper";

interface AddressDetails {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  altContact: string;
  address: string;
  district: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  _id?: string
}

interface Props {
  data: AddressDetails;
  selectAddress: () => void;
  isSelected?: boolean;
  newAddress?: boolean;
  cancelNewAddress?: () => void;
  selectNewAddress?: () => void;
  onNewAddressAdded?: () => void;
  setNewAddress?: (address: any) => void
}



const ShippingAddressCart: FC<Props> = ({
  data,
  selectAddress,
  isSelected = false,
  newAddress = false,
  cancelNewAddress = () => { },
  onNewAddressAdded = () => { },
  setNewAddress = () => { },
}) => {
  const [userDetails, setUserDetails] = useState<any>()
  const [addressDetails, setAddressDetails] = useState<AddressDetails>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    address: "",
    district: "",
    city: "",
    state: "",
    country: "",
    altContact: "",
    pincode: "",
    _id: ""
  });

  const [edit, setEdit] = useState<Boolean>(newAddress);

  useEffect(() => {
    setAddressDetails({ ...data });
    const user = getLocalStorage('user_details')
    setUserDetails(user)
  }, [data]);

  async function updateAddress() {
    const error = verifyAddress({ ...addressDetails, email: userDetails.email })
    if (error != null) {
      toast.error(error);
      return
    }
    const res = await updateUserAddress(addressDetails._id, { ...addressDetails, email: userDetails.email })
    if (res && res.data) {
      toast.success("Address Successfully Updated");
      setEdit(false)
      window.location.reload()
    } else {
      toast.error(res?.data?.message);
    }
  }

  const handleAddNewAddress = async () => {
    const verificationErr = verifyAddress({ ...addressDetails, email: userDetails.email })
    if (verificationErr != null) {
      toast.error(verificationErr, { duration: 2000 })
      return
    }
    const res = await addUserAddress({ ...addressDetails, email: userDetails.email })
    if (res.errRes) {
      console.log(res.errRes)
      toast.error(res.errRes.data.message)
      return
    }
    onNewAddressAdded()
  }

  const checkLeadingSpace = (input: string, name: string) => {
    if (name == "firstName" && input == " " && addressDetails.firstName == "") return true
    if (name == "lastName" && input == " " && addressDetails.lastName == "") return true
    if (name == "address" && input == " " && addressDetails.address == "") return true
    if (name == "email" && input == " " && addressDetails.email == "") return true
    if (name == "district" && input == " " && addressDetails.district == "") return true
    if (name == "city" && input == " " && addressDetails.city == "") return true
    if (name == "state" && input == " " && addressDetails.state == "") return true

    return false
  }

  const handleAddressChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e: any) => {
    if (checkLeadingSpace(e.target.value, e.target.name)) return
    if (e.target.type == "number") {
      if ((e.target.name == "phoneNo" || e.target.name == "altContact") && e.target.value.length > 10) {
        toast.error("Maximum length is 10 digits")
        return
      }
      if (e.target.name == "pincode" && e.target.value.length > 6) {
        toast.error("Invalid Pin-Code")
        return
      }
    }
    setAddressDetails(state => ({
      ...state,
      [e.target.name]: e.target.value
    }))
    if (newAddress) {
      setNewAddress({
        ...addressDetails,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleCancelAddressChange = () => {
    if (newAddress) {
      cancelNewAddress()
      return
    }
    setAddressDetails({ ...data })
    setEdit(false)
  }

  const renderShippingAddress = () => {
    return (
      <div onClick={() => {
        if (!edit)
          selectAddress()
      }}
        className={`cursor-pointer border rounded-xl ${isSelected ? "border-black dark:border-black " : "border-slate-200 dark:border-slate-700"}`}
      >
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          {
            !newAddress && (

              <div className="flex justify-between w-full" key={data._id}>
                <div className="sm:ml-8">
                  <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                    <span className="uppercase">{`${data.firstName} ${data.lastName}`}</span>
                    {/*
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2.5"
                      stroke="currentColor"
                      className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                    */}
                  </h3>
                  <div className="font-semibold mt-1 text-sm">
                    <span className="">
                      {`${data.address}, ${data.district}, ${data.city}, ${data.state}, ${data.country} - ${data.pincode}`}
                    </span>
                  </div>
                </div>
                <div className="flex justify-around gap-5">
                  {/* <ButtonSecondary
                    sizeClass="py-2 px-4 "
                    fontSize="text-sm font-medium"
                    className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                    onClick={() => {isActive ? setIsActive(false) : setIsActive(true)}}
                  >
                    Change
                  </ButtonSecondary> */}
                  <div className="flex flex-col justify-center">
                    <button
                      className="p-3 w-[50px] bg-slate-100 hover:bg-slate-200 rounded-xl"
                      onClick={(e) => {
                        e.stopPropagation()
                        setEdit(state => !state)
                      }}
                    >
                      <img src={(edit) ? CancelIcon : EditIcon} alt="Change" className="w-full h-full" />
                    </button>
                  </div>
                </div>
              </div>
            )
          }
        </div>

        {
          edit && (
            <div
              className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 `}
            >
              <AddressForm value={addressDetails} onChange={handleAddressChange} />
              {
                newAddress ? (
                  <div className="flex flex-col sm:flex-row pt-6">
                    <ButtonPrimary className="sm:!px-7 shadow-none" onClick={handleAddNewAddress}>
                      Save & Use
                    </ButtonPrimary>
                    <ButtonSecondary
                      className="mt-3 sm:mt-0 sm:ml-3 border border-2 border-slate-900 w-[120px]"
                      onClick={selectAddress}
                    >
                      Use
                    </ButtonSecondary>
                    <ButtonSecondary
                      className="mt-3 sm:mt-0 sm:ml-3"
                      onClick={cancelNewAddress}
                    >
                      Cancel
                    </ButtonSecondary>
                  </div>
                ) : (
                  <div className="flex flex-col sm:flex-row pt-6">
                    <ButtonPrimary className="sm:!px-7 shadow-none" onClick={updateAddress}>
                      Save
                    </ButtonPrimary>
                    <ButtonSecondary
                      className="mt-3 sm:mt-0 sm:ml-3"
                      onClick={handleCancelAddressChange}
                    >
                      Cancel
                    </ButtonSecondary>
                  </div>
                )
              }
            </div>
          )
        }

      </div>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddressCart;
